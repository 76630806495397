<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body" style="padding-top: 40px;">
    <div class="row topfeatures">
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).revenue + ' (' + this.loggedPartner?.data?.totals.data.newOrdersNumber + ')'}}</h5>
                    <div>
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.newOrdersTotalAmount).lev.toLocaleString()  }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.newOrdersTotalAmount).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;" href="#" class="btn revenue"></a>
                    </div>
                </div>
              </div>
        </div>
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).balance}}</h5>
                    <div >
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.monthRevenue).lev.toLocaleString()  }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.monthRevenue).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;" href="#" class="btn balance"></a>
                    </div>
                </div>
              </div>
        </div>
        <div class="col-sm-4 column">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{this.dashboardLables(this.getPageName()).bankSave}}</h5>
                    <div >
                        <p class="card-text">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.yearRevenue).lev.toLocaleString()  }}.</p><span class="currency" style="color: black; font-weight: bold;">{{ this.priceConvertor(this.loggedPartner?.data?.totals.data.yearRevenue).coins }}</span> <span class="currency" style="font-weight: bold;"> лв</span>
                        <a style="float: right; top: -5px; position: relative;"  href="#" class="btn wallet"></a>
                    </div>
                </div>
              </div>
        </div>
    </div>
    <div class="row" style="margin: 0 !important; padding: 15px;">
        <div class='perPagePanel' style="float: right; right: 30px;">
            <label for="optionPerPage" style="color: #75809C;">Покажи: </label>
            <mat-form-field style="margin: 5px; height: 30px; width: 140px;">
                <mat-select [(value)]="this.numberPerPageValue" (selectionChange)="this.chooseNumberPerPage($event)" disableOptionCentering id="optionPerPage"  panelClass="selection-panel" class="multy-select-search">
                    <mat-option  [value]="5"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 5" >  <span style="margin: 20px;">{{5 + " " + this.dashboardLables(this.getPageName()).ordersLabel}}</span></mat-option>
                    <mat-option [value]="10"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 10" > <span style="margin: 20px;">{{10 + " " + this.dashboardLables(this.getPageName()).ordersLabel}}</span></mat-option>
                    <mat-option [value]="20"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == 20" > <span style="margin: 20px;">{{20 + " " + this.dashboardLables(this.getPageName()).ordersLabel}}</span></mat-option>
                    <mat-option [value]="-1"><input style="margin: 20px; margin-left: -8px; margin-top: 17px;" name="flexCheckChecked"  class="form-check-input" type="checkbox" [checked]="this.numberPerPageValue == -1" > <span style="margin: 20px;">{{this.dashboardLables(this.getPageName()).all}}</span></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <br>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" style="width: 250px;">
              <a class="nav-link active" style="display: inline-block;" id="home-tab" (click)="this.resetPage()" data-bs-toggle="tab" href="#products" role="tab" aria-controls="products" aria-selected="true">Поръчки</a>
              <button (click)="this.openModal(orderHelpWindow)" class="btn btn-primary orderInfo" style="margin-left: 15px; width: 40px; height: 40px; position: relative; top: -10px; display: inline-block; border-radius: 10px; background-color: white; border: 1px solid #B6BFD6; margin-right: 10px;" mat-button>
                <img style="width: 25px; height: 25px; position: relative; left: -7px; top: -2px;" src="/assets/images/dashboard/orders/orderinfo.svg" alt="ShareMe company logo">
            </button>

            </li>
        </ul>
    </div>
    <div class="tab-content" id="myTabContent" style="padding: 0;">

        <!-- ----------------------
            PRODUCTS
        ---------------------- -->

        <div class="tab-pane fade show active" id="products" style="padding: 0;" role="tabpanel" aria-labelledby="home-tab">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12" style="padding: 0;">
                        <ul style="float: left;" class="nav nav-tabs products-nav" id="myTab2" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classEndTab('waiting')" id="home-tab2" (click)="this.changeStateFilter('waiting')" data-bs-toggle="tab" href="#inventory" role="tab" aria-controls="inventory" aria-selected="true">Нови</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classTab('confirmed')"   style="border-left: 1.5px solid #0A69EB; border-right: 1.5px solid #0A69EB;" (click)="this.changeStateFilter('confirmed')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">В процес</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classTab('delivered')"   style=" border-right: 1.5px solid #0A69EB;" (click)="this.changeStateFilter('delivered')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">Доставени</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classTab('cancelled')"   style=" border-right: 1.5px solid #0A69EB;" (click)="this.changeStateFilter('cancelled')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">Отказани</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classTab('returned')"   style=" border-right: 1.5px solid #0A69EB;" (click)="this.changeStateFilter('returned')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">Върнати</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a [class]="this.classEndTab('all')" id="profile-tab2" (click)="this.changeStateFilter('all')" data-bs-toggle="tab" href="#archive" role="tab" aria-controls="archive" aria-selected="false">Всички</a>
                            </li>
                        </ul>
                        <div style="float: right;">
                            <div class="input-group flex-nowrap" style="margin-right: 10px; width: auto; display: inline-flex;">
                                <div class="search-field-wrapper" style="position: relative; top: -15px;">
                                    <div class="input-group mb-2 mr-sm-2">
                                        <div class="input-group-prepend" *ngIf="!this.searchString">
                                            <fa-icon [icon]="faSearch"></fa-icon>
                                        </div>
                                        <input (input)="this.setSearchedText($event)" type="text" class="form-control fa" style="font-size: unset; height: unset; border: 2px solid #F3F6FB !important; border-top-left-radius: 5px !important; border-bottom-left-radius: 5px !important;" [value]="this.searchString" [placeholder]="'      ' + this.dashboardLables(this.getPageName()).search" aria-label="Username" aria-describedby="addon-wrapping">
                                        <button *ngIf="this.searchString" (click)="this.clearSearchString()" type="button" class="btn bg-transparent" style="margin-left: -45px; z-index: 100;">
                                            <i class="fa fa-times" style="content: url('/assets/images/dashboard/products/cross.svg');"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>
                        <br>
                        <ngb-alert *ngIf="this.isReturnedTab" [dismissible]="false" style="right: 10px; background-color: #FDF5DB !important; color: #20213D !important; margin-top: 20px;">
                            <div class="alertIcon" style="display: inline-block; position: relative; margin-right: 10px; top: -10px;"></div> <div style="display: inline-block;  width: 95%;"> Имате върната поръчка! Трябва да се свържете с клиента и да дадете адреса за връщане на продукта, ако не е въведен досега. Имате 14 дни да обработите и завършите
                                заявката за връщане, в противен случай тя ще бъде затворена автоматично и ще бъде начислена такса 5% за неспазване на срока.</div>
                        </ngb-alert>

                        <ngb-alert *ngIf="this.status == 'waiting'" [dismissible]="false" style="right: 10px; background-color: #FDF5DB !important; color: #20213D !important; margin-top: 20px;">
                            <div class="alertIcon" style="display: inline-block; position: relative; margin-right: 10px; top: 0px;"></div> <div style="display: inline-block;  width: 95%;">Потвърдете поръчката, за да видите данните на клиента. Уверете се, че ще изпълнителните поръчката, на следващ етап не може да откажете
                                продукт, а само цяла поръчка.</div>
                        </ngb-alert>

                        <ul *ngIf="this.status == 'confirmed'" class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <a class="nav-link active" id="home-tab" (click)="this.resetFilters()" data-bs-toggle="tab"  role="tab" aria-controls="products" aria-selected="true">Всички</a>
                            </li>
                            <li class="nav-item" role="presentation" >
                              <a class="nav-link" id="profile-tab" (click)="this.getConfimedOrders()" data-bs-toggle="tab"  role="tab" aria-controls="services" aria-selected="false">Потвърдени</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="home-tab" (click)="this.getForPickupOrders()" data-bs-toggle="tab" role="tab" aria-controls="products" aria-selected="false">Заявени за куриер</a>
                              </li>
                              <li class="nav-item" role="presentation">
                                <a class="nav-link" id="home-tab" (click)="this.getForDelivery()" data-bs-toggle="tab" role="tab" aria-controls="products" aria-selected="false">За доставка</a>
                              </li>
                        </ul>

                        <div class="tab-content" id="nav-tabContent2" style="right: 10px; position: relative;">

                            <!-- ----------------------
                                    Inventory
                            ---------------------- -->


                            <div class="tab-pane fade show active orders" id="inventory" role="tabpanel" aria-labelledby="nav-home-trdersDaab">
                                <table mat-table  (scroll)="onTableScroll($event)" (matSortChange)="announceSortChange($event)" multiTemplateDataRows  [dataSource]="this.tableDataSource" matSort  class="mat-elevation-z8 products">
                                    <ng-container matColumnDef="ID">
                                        <th mat-header-cell *matHeaderCellDef > {{this.columnNumberName}} </th>
                                        <td mat-cell *matCellDef="let element" style="width: 75px;">{{element.uuid}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Date">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.columnDateName}}</th>
                                        <td mat-cell *matCellDef="let element" class="product-name-field"> {{ this.getDate(element).date }} <br> {{ this.getDate(element).time }} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Type">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).type}} </th>
                                        <td mat-cell *matCellDef="let element">
                                        {{this.checkGroupType(element.group)}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Client">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).client}} </th>
                                        <td mat-cell *matCellDef="let element">
                                            <span> {{ element.delivery_address?.name }} <br> <span style="color: #75809C;"> {{ element.delivery_address?.mobile_phone_1_e164 }} </span> </span>
                                    </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Method">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).method}} </th>
                                        <td mat-cell *matCellDef="let element"> {{this.paymentType(element)}} </td>
                                    </ng-container>


                                    <ng-container matColumnDef="Sum">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).sum}} </th>
                                        <td mat-cell *matCellDef="let element"> {{this.convertPrice(element.total_gross + element.shipment_cost)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="EndDate">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).enddate}} </th>
                                        <td mat-cell *matCellDef="let element"> {{ this.addTwoWeeksToDate(element).date }}  <br>  {{ this.addTwoWeeksToDate(element).time }}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="Delivery">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).delivery}} </th>
                                        <td mat-cell *matCellDef="let element"> {{this.deliveryMethod(element)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Status">
                                        <th mat-header-cell *matHeaderCellDef>
                                          {{this.dashboardLables(this.getPageName()).status}}&nbsp;
                                          <small *ngIf="status === 'waiting'" id="statusWaitingHelp" class="text-muted" data-bs-toggle="tooltip" title="Време за потвърждение на поръчката.След изтичане на срока, автоматично ще бъде отказана и ще бъдат възстановени парите на клиента."></small>
                                          <small *ngIf="status === 'delivered'" id="statusDeliveredHelp" class="text-muted" data-bs-toggle="tooltip" title="След като порърката е доставена до клиента, автоматично ще премине в статус 'Доставена'."></small>
                                        </th>
                                        <td mat-cell *matCellDef="let element" [style]="'color: ' + this.getOrderStatusColor(element) + ';'"> {{this.convertOrderStatus(element, element.state)}}
                                            <span *ngIf="element.state == 'waiting'" style="color: #75809C;"><br> Остават: {{ element.timeToConfirm.days }} дни {{ element.timeToConfirm.hours }} часа</span></td>
                                    </ng-container>


                                    <ng-container matColumnDef="ReturnedSum">
                                        <th mat-header-cell *matHeaderCellDef> {{this.dashboardLables(this.getPageName()).returnedsum}} </th>
                                        <td mat-cell *matCellDef="let element"> {{this.convertOrderStatus(element.amount_refunded)}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Price">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).price}} </th>
                                        <td mat-cell *matCellDef="let element">{{this.convertPrice(element.total_gross + element.shipment_cost)}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="Tax">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.dashboardLables(this.getPageName()).tax}} <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Начислява се такса при отказ на Потвърдени поръчки.11% с ДДС."></small> </th>
                                        <td mat-cell *matCellDef="let element"> {{this.convertPrice(this.totalTaxReturn(element))}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="ShareMe">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ShareMe%  <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Комисион за успешно завършена поръчка. 11% с ДДС."></small></th>
                                        <td mat-cell *matCellDef="let element"> {{this.convertPrice(this.getShareMeFee(element))}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Net">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{this.dashboardLables(this.getPageName()).returnFee}} <small id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Начислява се таксата при въстановяване на сума, като се приспада комисионна за доставена поръчка (5% с ДДС)."></small></th>
                                        <td mat-cell *matCellDef="let element"> {{this.convertPrice(this.getRefundFee(element))}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Confirm">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span class="confirm" (click)="this.confirmOrder(element, $event)" [ngbTooltip]="'Потвърди'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="PickUp">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span *ngIf="this.isLoggedPartener && this.shouldShowPickUpBtn(element)" class="pickup" (click)="this.pickup(element)"  [ngbTooltip]="'Заяви за вземане от куриер'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Generate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span [class]="this.isLoggedPartener && this.shouldShowPickUpBtn(element) ? 'generate_inactive' : 'generate'" (click)="this.printWayBill(element, $event)" [ngbTooltip]="'Отпечатай товарителница'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Print">
                                        <th mat-header-cell *matHeaderCellDef  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span class="print" (click)="this.printStockReciept(element, $event)" [ngbTooltip]="'Отпечатай стокова разписка '" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Cancel">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span class="decline" (click)="this.openCancelOrderDialog(element, $event)" [ngbTooltip]="'Откажи'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Return"  style="width: 50px !important; text-align: center !important;">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header  style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span class="restore" (click)="this.openReturnOrderDialog(element, $event)" [ngbTooltip]="'Върни'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="CancelReturn">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header   style="width: 50px !important; text-align: center !important;"></th>
                                        <td mat-cell *matCellDef="let element"  style="width: 50px !important; text-align: center !important;">
                                            <span class="decline" (click)="this.cancelReturn(element)" [ngbTooltip]="'Откажи'" [openDelay]="300" [closeDelay]="500" style="cursor: pointer; z-index: 10000000;"></span>
                                        </td>
                                    </ng-container>


                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForOrdersTable"></tr>
                                    <tr (click)="this.goToDetails(element, $event)" mat-row *matRowDef="let element; columns: displayedColumnsForOrdersTable;"
                                        class="example-element-row"
                                        [class.example-expanded-row]="expandedElement === element"
                                        [style]="(element == expandedElement) ? '' : 'box-shadow: -2px -2px 2px rgb(153 163 191 / 10%), 2px -2px 2px rgb(153 163 191 / 10%), 2px 2px 2px rgb(153 163 191 / 10%);'">
                                    </tr>
                                </table>
                                <nav *ngIf="this.numberPerPageValue > -1 && this.ordersDataSource.data.length" style="margin-top: 10px;" aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" *ngIf="this.page > 1">
                                            <a style="cursor: pointer;" class="page-link previous" (click)="this.goToPreviousPage()" tabindex="-1">{{this.dashboardLables(this.getPageName()).previousPage}}</a>
                                        </li>
                                        <li class="page-item">
                                            <input type="number" class="form-control current-page" style="width: 50px !important;" [value]="this.page" (change)="this.loadSpecificPage($event.target.value)">
                                        </li>
                                        <li class="page-item" *ngIf="this.page < this.lastPage">
                                            <a style="cursor: pointer;" class="page-link next" (click)="this.goToNextPage()">{{this.dashboardLables(this.getPageName()).nextPage}}</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                            <div *ngIf="this.ordersDataSource.data.length == 0" style="text-align: center; margin-bottom: 50px; z-index: 1001;" class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">
                                <img src="/assets/images/dashboard/products/noorders.svg" style="margin: auto; margin-top: 40px; z-index: 1001;" width="200" height="200" alt="">
                                <h3 style="color: black; z-index: 1001; font-size: 21px;">Все още нямате поръчки <br>
                                    <span style="color: #75809C; z-index: 100000; font-size: 16px;">На този екран ще виждате списък с направените <br> поръчки</span> <br> <br>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-template #changeSpeedyUsername let-modal>
    <div style="text-align: center; padding: 20px;">
        <h4>Генериране на товарителница</h4>
        <h6>Поръчка № {{this.selectedOrder.uuid}}</h6>
        <h5 style="color: #99A3BF;">След генериране на товарителница, може да заявите пратката за вземане от посочения от Вас адрес и час от меню “Логистика”</h5>
        <div class="modal-body" style="text-align: center; font-weight: 400;">
            <form class="form-group" style="box-shadow: unset;">
                <div class="row">
                    <label class="col-5">Брой пакети<span class="delivery-req-fields">*</span>:</label>
                    <input (change)="this.validateParcels($event.target.value, $event)" type="number" class="form-control mx-sm-4 col-5" id="speedyUsername" aria-describedby="emailHelp" [value]="this.numberOfParcels">
                </div>

            </form>
        </div>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="background-color: white; border: 1px solid #B6BFD6; color: black; float: left;" mat-button (click)="modal.close()">Затвори</button>
            <button class="btn btn-primary" style="float: right;" mat-button (click)="this.generateShipment(modal)" cdkFocusInitial>Генерирай</button>
          </div>
    </div>
</ng-template> -->

<ng-template #orderHelpWindow let-modal>
    <div  style="text-align: center; padding: 30px;">
        <h4>Как да изпълните поръчка?</h4>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <h5>1. Потвърдете поръчката</h5>
                <p style="color: #75809C;">Потвърдете поръчката, за да станат видими данните на клиента.</p>
            </li>
            <li class="list-group-item">
                <h5>2. Отпечатайте стокова разписка</h5>
                <p style="color: #75809C;">След като поръчката е потвърдена и сте подготвили продуктите отпечатайте стоковата разписка, ако искате да я приложите при доставка. Не е задължително, но за клиентска удовлетвореност.</p>
            </li>
            <li class="list-group-item">
                <h5>3. Генерирайте и отпечатете товарителницата към куриера</h5>
                <p style="color: #75809C;">Генерирайте товарителницата към куриера и я отпечатете, за да я предадете на куриера при предаване на пратката.</p>
            </li>
            <li class="list-group-item">
                <h5>4. Заявете пратката за вземане (или я доставете лично)</h5>
                <p style="color: #75809C;">Ако сте избрали метод на изпращане от „Адрес на подател“, заявете пратката за вземане, чрез натискане на бутона „Заяви за вземане от куриер“, а ако метода на изпращане е от „Офис на куриер“ сами доставете пратката до офиса на куриера.</p>
                <p style="color: #75809C;">След успешно предаване на пратката на куриер, поръчката автоматично ще премине в статус „Доставена“, щом бъде доставена.</p>
            </li>
            <li class="list-group-item">
                <p style="color: #75809C;">При допълнителни въпроси, погледнете секция „Поръчки“ за търговци: <a href="https://shareme.co/help">https://shareme.co/help</a> или се свържете с нас: <a href="mailto:office@shareme.cо">office@shareme.cо</a></p>
            </li>
        </ul>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="border-radius: 10px; background-color: white; border: 1px solid #B6BFD6; width: 45%; margin-right: 10px; color: black;" mat-button (click)="modal.close()">Затвори</button>
        </div>
    </div>
</ng-template>


<ng-template #cancelWindow let-modal>
    <div style="text-align: center; padding: 30px;">
        <h4>Желаете ли да откажете поръчката?</h4>
        <h6 style="color: #99A3BF; margin-top: 30px; margin-bottom: 30px;">{{ this.cancelRequestText }}</h6>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="border-radius: 10px; color: #E53E3E; background-color: white; border: 1px solid #B6BFD6;  width: 45%; margin-right: 10px;" mat-button (click)="this.cancel(this.selectedOrder)">Откажи поръчка</button>
            <button class="btn btn-primary" style="border-radius: 10px; width: 45%;" mat-button (click)="modal.close()" cdkFocusInitial>Не сега</button>
          </div>
    </div>
</ng-template>

<ng-template #returnWindow let-modal>
    <div style="text-align: center; padding: 30px;">
        <h4>{{this.returnWindowTitle}}</h4>
        <h6 style="color: #99A3BF; margin-top: 30px; margin-bottom: 30px;">{{ this.returnWindowText }}</h6>
        <div class="container">
            <div class="row">
                <div class="col-sm-6" style="padding: 0;">
                    Сума на продукт:
                </div>
                <div class="col-sm-6" style="padding: 0;">
                    {{ this.convertMoneySum(this.selectedOrder.total_gross) }}
                </div>
                <div class="col-sm-6" style="padding: 0;">
                    Възстановена сума:
                </div>
                <div class="col-sm-6" style="padding: 0;">
                    {{ this.convertMoneySum(this.selectedOrder.total_gross) }}
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button class="btn btn-primary" style="border-radius: 10px; color: #E53E3E; background-color: white; border: 1px solid #B6BFD6;  width: 45%; margin-right: 10px;" mat-button (click)="modal.close()">Затвори </button>
            <button class="btn btn-primary" style="border-radius: 10px; width: 45%;" mat-button (click)="this.confirmReturn()" cdkFocusInitial>Не сега</button>
          </div>
    </div>
</ng-template>
