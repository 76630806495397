<app-loading *ngIf="this.isLoading"></app-loading>
<div class="container dashboard-body">
    <div class="row">
        <h4 style="margin-bottom: 35px; margin-top: 10px;">Фактури</h4>
    </div>
    <ngb-alert [dismissible]="false" style="background-color: #FDF5DB !important; color: #20213D !important">
      <div class="alertIcon" style="display: inline-block; position: relative; margin-right: 10px; top: -10px;"></div> <div style="display: inline-block;  width: 95%;"> Ако сумата “За получаване" е отрицателна, моля преведете дължимата сума до 4 дни на посочената във фактурата банкова сметка на "ШеърМи" АД, за да продължите да продавате и продуктите ви да са видими от клиентите.</div>
    </ngb-alert>
    <table mat-table multiTemplateDataRows [dataSource]="this.ordersReport" matSort class="mat-elevation-z8 products">

      <ng-container matColumnDef="Period">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef >Период</th>
            <td mat-cell *matCellDef="let element" style="width: 50px !important">{{ timeInterval(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="FOrders">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>Завършени поръчки <small id="deliveryOrders" class="text-muted" data-bs-toggle="tooltip" title="Включва стойността на Доставените поръчки в секция Завършени."></small></th>
            <td mat-cell *matCellDef="let element" class="product-name-field"> {{ priceFormat(element.total_delivered_amount) }} </td>
        </ng-container>

        <ng-container matColumnDef="ShareMe">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>ShareMe <br> Комисион 11% <small id="shareMeFees" style="position: relative; top: -7px; right: 20px;" class="text-muted" data-bs-toggle="tooltip" title="Начислената комисионна на Завършените (Доставените) поръчки. За поръчки с НП, не се начислява комисион върху стойността на доставката."></small></th>
            <td mat-cell *matCellDef="let element">
                {{ priceFormat(element.total_commission) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Taxes">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef>Такси <small id="taxes" class="text-muted" data-bs-toggle="tooltip" title="Включва такса отказване, такса възстановяване и банков превод."></small></th>
            <td mat-cell *matCellDef="let element"> {{ priceFormat(element.total_fees) }} </td>
        </ng-container>

        <ng-container matColumnDef="Returned">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef>Върнати поръчки с <br> карта <small id="returned" class="text-muted" data-bs-toggle="tooltip" title="Възстановените суми, чрез ShareMe, за поръчки платени с карти."></small></th>
            <td mat-cell *matCellDef="let element"> {{ priceFormat(element.total_refunded_with_card) }} </td>
        </ng-container>

        <ng-container matColumnDef="ToRecieve">
            <th style="width: 14.2% !important;"  mat-header-cell *matHeaderCellDef mat-sort-header>За получаване <small style="position: relative; top: 2px;" id="emailHelp" class="text-muted" data-bs-toggle="tooltip" title="Сумата на завършилите (доставени) поръчки, минус таксите и възстановените суми, чрез ShareMe, платени с карта."></small></th>
            <td style="padding-top: 15px !important; padding-bottom: 15px !important;" mat-cell *matCellDef="let element">
              <span>{{ priceFormat(element.amount_receivable) }}</span>
              <button style="margin-left: 50px;"  [ngbTooltip]="'Виж детайли'"  class="btn btn-outline-primary variations-btn"><span [class]="expandedElement === element ? 'variations-opened' : 'variations'"></span></button>
            </td>
        </ng-container>


        <ng-container matColumnDef="Invoice">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumnsForInvoiceTable.length" style="padding-left: 15px; padding-top: 8px; padding-bottom: 8px;">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement  ? 'expanded' : 'collapsed'">
              <div class="container expanded-section">
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-5 pr-0 title">Доставени с карта:</div>
                      <div class="col-md-7 pl-0">{{ priceFormat(element.total_delivered_card) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Отказани с такса:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_cancelled_with_fees) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Върнати поръчки с карта:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_refunded_with_card) }}</div>
                    </div>
                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-5 pr-0 title">Доставени с НП:</div>
                      <div class="col-md-7 pl-0">{{ priceFormat(element.total_delivered_cod) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Отказани без такса:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_cancelled_without_fees) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Върнати поръчки с НП:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_refunded_with_bank) }}</div>
                    </div>
                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-5 pr-0 title">Общо доставени:</div>
                      <div class="col-md-7 pl-0">{{ priceFormat(element.total_delivered_amount) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Общо отказани:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_cancelled_amount) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Общо върнати:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_refunded_amount) }}</div>
                    </div>
                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-5 pr-0 title">Комисион 11%: <small id="fee" class="text-muted" [ngbTooltip]="'Сумите са с включен ДДС'" ></small></div>
                      <div class="col-md-7 pl-0">{{ priceFormat(element.total_commission) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Такса отказ 11%: <small id="fee1" class="text-muted" [ngbTooltip]="'Сумите са с включен ДДС'" ></small></div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_cancellation_fees) }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Такса връщане 5%: <small id="fee2" class="text-muted" [ngbTooltip]="'Сумите са с включен ДДС'" ></small></div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_refunded_fees) }}</div>
                    </div>
                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Възстановен комисион 11%: <small id="fee3" class="text-muted" [ngbTooltip]="'Сумите са с включен ДДС'" ></small></div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_delivery_revert_fees) }}</div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-7 pr-0 title">Сума на доставка с НП:</div>
                      <div class="col-md-5 pl-0">{{ priceFormat(element.total_delivered_cod_shipping) }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Ваучери:</div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.total_voucher_amount) }}</div>
                    </div>
                  </div>
                  <div class="col-md-5">

                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-7 pr-0 title">Сума на доставки с карта:</div>
                      <div class="col-md-5 pl-0">{{ priceFormat(element.total_delivered_card_shipping) }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-6 pr-0 title">Такса превод: <small id="fee4" class="text-muted" [ngbTooltip]="'Сумите са с включен ДДС'" ></small></div>
                      <div class="col-md-6 pl-0">{{ priceFormat(element.wire_transfer_fee) }}</div>
                    </div>
                  </div>
                  <div class="col-md-5">

                  </div>
                </div>
                <div class="row tax-row">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-md-7 pr-0 title">Обаща сума на доставки:</div>
                      <div class="col-md-5 pl-0">{{ priceFormat(element.total_delivered_card_shipping, element.total_delivered_cod_shipping) }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">

                  </div>
                  <div class="col-md-3">

                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsForInvoiceTable"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumnsForInvoiceTable;"
          (click)="expandedElement = expandedElement === element ? null : element"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          [style]="(element == expandedElement) ? '' : 'box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 12px;'">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']; let element;" class="example-detail-row" [style]="element == expandedElement ? '' : 'top: 0px; box-shadow: none;'"></tr>
    </table>
    <div *ngIf="this.ordersReport.length == 0" style="text-align: center; margin-bottom: 50px; z-index: 1001;" class="tab-pane fade show active" id="inventory" role="tabpanel" aria-labelledby="nav-home-tab">
        <img src="/assets/images/dashboard/products/noinvoices.svg" style="margin: auto; margin-top: 40px; z-index: 1001; width: 400px; height: 250px;" width="300" height="300" alt="">
        <h3 style="color: black; z-index: 1001; font-size: 21px;">Все още нямате издадени фактури<br>
            <span style="color: #75809C; z-index: 100000; font-size: 16px;">На този екран ще виждате списък с издадените <br> фактури за съответния период</span> <br> <br>
        </h3>
    </div>

</div>
