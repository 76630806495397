import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LocalstorageserviceService } from 'src/services/infrastructureServices/localstorageservice/localstorageservice.service';
import { ImagesService } from 'src/services/requestServices/images/images.service';
import { PartnersService } from 'src/services/requestServices/partners/partners.service';
import { UsersService } from 'src/services/requestServices/users/users.service';
import { CarrierService } from 'src/services/requestServices/V2/carrier/carrier.service';
import { DocumentsService } from 'src/services/requestServices/V2/documents/documents.service';
import { ImageService } from 'src/services/requestServices/V2/image/image.service';
import { InfoService } from 'src/services/requestServices/V2/info/info.service';
import { LoadingstateService } from 'src/services/stateServices/loading/loadingstate.service';
import { LoggeduserinfoService } from 'src/services/stateServices/loggeduserinfoservice/loggeduserinfo.service';
import { UserinfostoreService } from 'src/services/stateServices/userinfoservice/userregistrationstateservice.service';
import { LoggedinfoService } from 'src/services/stateServices/V2/loggedinfo/loggedinfo.service';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { InvoicesService } from '../../../services/requestServices/V2/invoices/invoices.service';
import { Invoices } from '../../../models/DTO/V2/invoices/Invoices';
import { Invoice } from '../../../models/DTO/V2/invoices/invoice';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoicesComponent extends BaseDashboardComponent implements OnInit, AfterViewInit {

  public ordersReport: Invoice[] = [];
  public displayedColumnsForInvoiceTable: string[] = ['Period', 'FOrders', 'ShareMe', 'Taxes', 'Returned', 'ToRecieve'];
  public expandedElement: Invoice | null;
  public isLoading = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userStateService: UserinfostoreService,
    protected localStorageService: LocalstorageserviceService,
    protected loggeduserinfoservice: LoggeduserinfoService,
    protected router: Router,
    protected imagesService: ImagesService,
    protected sanitizer: DomSanitizer,
    protected usersService: UsersService,
    protected partnersService: PartnersService,
    private toastr: ToastrService,
    protected documentService: DocumentsService,
    protected loggedInfoService: LoggedinfoService,
    protected infoService: InfoService,
    protected imageService: ImageService,
    protected carrierService: CarrierService,
    private loadingState: LoadingstateService,
    private invoiceServices: InvoicesService

  ) {
    super(activatedRoute, userStateService, localStorageService, usersService, loggeduserinfoservice,
      partnersService, imagesService, sanitizer, router, loggedInfoService, infoService, imageService, carrierService, documentService);
    this.pageName = 'invoice';
  }

  ngOnInit(): void {
    // this.loadingState.loading$.subscribe(result => {
    //   this.isLoading = result;
    // });
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.loadTooltips();
  }

  private loadData(): void {
    // this.loadingState.changeLoading(true);
    this.isLoading = true;
    this.invoiceServices.getOrdersReport().pipe(
      catchError(error => {
        console.error('Error fetching invoices:', error);
        this.toastr.error('Грешка при зареждане на фактури', 'Грешка');
        return of({ data: [] });
      })
    ).subscribe((invoices: Invoices): void => {
      this.ordersReport = invoices.data.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime());
      // this.loadingState.changeLoading(false);
      this.isLoading = false;
    });
  }

  timeInterval(element: Invoice): string {
    const startDate = new Date(element.start_date);
    const endDate = new Date(element.end_date);

    const formatDate = (date: Date, includeYear: boolean = false) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return includeYear ? `${day}.${month}.${year}` : `${day}.${month}`;
    };

    return `${formatDate(startDate)} - ${formatDate(endDate, true)}`;
  }

  priceFormat(element?: string, element2?: string): string {
    const amount1 = parseFloat(element || '0');
    const amount2 = parseFloat(element2 || '0');
    return (amount1 + amount2).toFixed(2) + ' лв';
  }
}
