import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Invoices } from '../../../../models/DTO/V2/invoices/Invoices';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  private invoiceURL: string = environment.api + 'orders/report';

  constructor(private httpClient: HttpClient) { }

  getOrdersReport(): Observable<Invoices> {
    return this.httpClient.get<Invoices>(this.invoiceURL).pipe(
      catchError(error => {
        console.error('Error fetching invoices:', error);
        return throwError(() => new Error('Грешка при зареждане на фактури'));
      })
    );
  }
}
